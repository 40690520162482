import { environment } from '@base/environments/environment';

export class QRMediaImage {
  public static BASEURL_CLOUDFRONT = environment.imageCloudfront;
  public static BASEURL_COUNTRY = environment.node;
  public static LOADING_LAZY = 'lazy';
  public static CROSSORIGIN_ANONYMOUS = 'anonymous';
  public static DECODING_ASYNC = 'async';
  public static LOADING_EAGER = 'eager';

  baseURL?: string;
  alt: string | undefined; // el texto alternativo de la imagen.
  srcset?: string; // una lista de archivos de imagen y sus tamaños correspondientes, y un conjunto de condiciones de medios y tamaños de imagen correspondientes para elegir la imagen adecuada.  srcset="ejemplo-1x.jpg 1x, ejemplo-2x.jpg 2x, ejemplo-3x.jpg 3x"
  sizes?: string; // una lista de archivos de imagen y sus tamaños correspondientes, y un conjunto de condiciones de medios y tamaños de imagen correspondientes para elegir la imagen adecuada.   sizes="(min-width: 768px) 50vw, 100vw"
  crossorigin?: string; // especifica que la imagen es una solicitud de origen cruzado y cómo manejar la política de CORS.
  usemap?: string; // especifican un mapa de imagen del lado del cliente y del lado del servidor.
  ismap?: string; // especifican un mapa de imagen del lado del cliente y del lado del servidor.
  width: string | undefined; // especifican el ancho de la imagen en píxeles.
  height: string | undefined; // especifican la altura de la imagen en píxeles.
  loading: string | undefined; // especifica que la imagen se cargará de forma perezosa (lazy) mientras el usuario se desplaza por la página.
  decoding: string | undefined; // especifica el modo de decodificación para la imagen.
  referrerpolicy?: string; // especifica que el navegador no debe enviar el encabezado Referer HTTP al solicitar la imagen.
  docFolder: string | undefined; // Nombre de la carpeta EJ: home/webp
  docName: string | undefined; // Nombre del archivo EJ banner-lendar
  docType: string | undefined; // Tipo de documento, puede ser WEBP o GIF
  text: string | undefined; // Texto descriptivo del banner a utilizar EJ: Publicidad
  link?: string; // Link de URL de destino EJ: https://lendar.com.ar
  className?: string; // Clase propia del banner. EJ: c-banner--lendar
}
