<img
  [src]="
    config.baseURL +
    '/' +
    config.docFolder +
    '/' +
    config.docName +
    '.' +
    config.docType
  "
  [alt]="config.alt"
  [attr.loading]="config.loading"
  [attr.decoding]="config.decoding"
  [attr.crossorigin]="config.crossorigin ? config.crossorigin : null"
  ismap
  referrerpolicy="no-referrer"
  [attr.height]="config.height"
  [attr.width]="config.width"
  [className]="config.className ? config.className : null" />
